import { useState } from "react";
import axios from "axios";
import APIEnv from "./APIEnv";
export default function Login() {
    const [accountInfo, setAccountInfo] = useState({
        username: "",
        password: ""
    })
    const handleLogin = async (e) => {
        e.preventDefault();
        if (accountInfo.username != "" && accountInfo.password != "") {
            try{
                const res = await axios.post(`${APIEnv.apiUrl}/api/user/login`, accountInfo)
                if (res.status == 200 && res.data.token) {
                    localStorage.setItem("bc7f398893fc", res.data.token);
                    closeLogin();
                    alert("Du är nu inloggad!")
                    window.location.reload();
                }
            } catch(e) {
                if(e.response?.data?.message){
                    alert(e.response.data.message)
                } else {
                    alert(e)
                }
            }
        } else {
            alert("Fyll i båda fälten.")
        }
    };
    const closeLogin = () => {
        document.getElementsByClassName("opacity")[0].style.display = "none"
        document.getElementsByClassName("loginWindow")[0].style.display = "none"
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAccountInfo({
            ...accountInfo,
            [name]: value
        });
    };

    return (
        <>
        <div className="d-flex justify-content-center">
            <div className='loginWindow p-3'>
                <form onSubmit={handleLogin} className="loginForm">
                    <div className="form-group mt-4">
                        <label htmlFor="username">Användarnamn</label>
                        <input type="text" className="form-control mt-2" id="usernamelogin" name="username" onChange={handleInputChange} />
                    </div>
                    <div className="form-group mt-4">
                        <label htmlFor="password">Lösenord</label>
                        <input type="password" className="form-control mt-2" id="passwordlogin" name="password" onChange={handleInputChange} />
                    </div>
                    <button type="submit" className="loginButton mt-4 p-1">Logga in</button>
                </form>

                <div className="x" onClick={closeLogin}>&#215;</div>
            </div>
            <div className='opacity'></div>
            </div>
        </>
    )
}