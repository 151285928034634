import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
import { useState, useEffect } from 'react';
import axios from 'axios';
import APIEnv from '../Components/APIEnv';

export default function Renoveringar() {
    renderPageText("renoveringar")

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [renoveringar, setRenoveringar] = useState([]);
    const [renOpen, setRenOpen] = useState(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchUsers = async (token) => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${token}`);

                setUser(res.data);
            } catch (e) {
                console.log(e)
            }
        };

        VerifyUser(false).then((res) => {
            setToken(res.token);

            return res.token
        }).then((res) => {
            if (res) {
                fetchUsers(res)
                
            }
        })
    }, [token]);
    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.post(`${APIEnv.apiUrl}/api/pagecontent`, {
                page: "renoveringar",
                content: "",
                section: 0
            });
            const blogEntries = res.data.matchingBlogEntries;
            setRenoveringar(blogEntries.reverse());
        };
        fetchData();
        setLoading(false)
    },[]);
    const newRen = async () => {
        const popUp = document.getElementsByClassName("popUpContainer")[0]
        popUp.innerHTML = ""
        popUp.style.opacity = "1";
        popUp.style.pointerEvents = "unset";
        popUp.className += " p-3";

        const title = document.createElement("input")
        title.type = "text"
        title.placeholder = "Titel"
        title.className = "mt-3 form-control"

        const text = document.createElement("textarea")
        text.placeholder = "Text"
        text.className = "mt-3 form-control"

        const noButton = document.createElement("button")
        noButton.innerHTML = "Avbryt"
        noButton.className = "noButton m-2"

        noButton.addEventListener("click", () => {
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
        })

        const yesButton = document.createElement("button")
        yesButton.innerHTML = "Publicering renovering"
        yesButton.className = "yesButton m-2"

        yesButton.addEventListener("click", async () => {
            if(text.value == "" || title.value == "") { 
                alert("Vänligen fyll i titel och text.")
            } else {
                try{
                    const res = await axios.post(`${APIEnv.apiUrl}/api/blogs/newpost`, {
                        title: title.value,
                        content: text.value,
                        user: token,
                        page: "renoveringar",
                        color: "null",
                        imagePath: "null"
                    });
                    alert("Renoveringen publicerad!")
                    window.location.reload()
                } catch(err){
                    console.log(err)
                }
            }
        })

        const buttonDiv = document.createElement("div")
        buttonDiv.className = "d-flex mt-3"
        buttonDiv.append(yesButton)
        buttonDiv.append(noButton)

        popUp.append("Ny renovering")
        popUp.append(title)
        popUp.append(text)
        popUp.append(buttonDiv)
    };
    const removeRen = async (blogId) => {
        const popUpWindow = document.getElementsByClassName("popUpContainer")[0];
        popUpWindow.style.opacity = 1;
        popUpWindow.style.pointerEvents = "unset";
        popUpWindow.innerHTML = ""

        // Create the first div with class "row"
        var row1 = document.createElement("div");
        row1.className = "row";

        // Create the first nested div with class "col-12" and text content
        var col12 = document.createElement("div");
        col12.className = "col-12";
        col12.textContent = "Vill du radera inlägget?";

        // Append the nested div to the first div
        row1.appendChild(col12);

        // Create the second div with class "row" and additional class "mt-3"
        var row2 = document.createElement("div");
        row2.className = "row mt-3";

        // Create the first nested div with class "col-8"
        var col8 = document.createElement("div");
        col8.className = "col-6";

        // Create a button with class "btn btn-primary deletePostButton" and text content
        var deleteButton = document.createElement("button");
        deleteButton.className = "deletePostButton";
        deleteButton.textContent = "Radera";
        deleteButton.addEventListener("click", async () => {
            try {
                const res = await axios.post(`${APIEnv.apiUrl}/api/blogs/deleteblog`, {
                    id: blogId,
                    userId: token
                });
                alert("Inlägget raderat!");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (e) {
                alert(e.message)
            }
        });

        // Append the button to the first nested div
        col8.appendChild(deleteButton);

        // Append the first nested div to the second div
        row2.appendChild(col8);

        // Create the second nested div with class "col-4"
        var col4 = document.createElement("div");
        col4.className = "col-6";

        // Create a button with class "btn btn-secondary deletePostButton" and text content
        var cancelButton = document.createElement("button");
        cancelButton.className = "deletePostButton";
        cancelButton.textContent = "Avbryt";
        cancelButton.addEventListener("click", function () {
            popUpWindow.style.opacity = 0;
            popUpWindow.style.pointerEvents = "none";
            setTimeout(() => {
                popUpWindow.innerHTML = "";
            }, 500)
        });

        // Append the button to the second nested div
        col4.appendChild(cancelButton);

        // Append the second nested div to the second div
        row2.appendChild(col4);

        // Append both divs to the document body or any other parent element
        popUpWindow.appendChild(row1);
        popUpWindow.appendChild(row2);
    };
    const editRen = async (blogId) => {
        const textElement = Array.from(document.getElementsByClassName("text")).find(obj => obj.id == blogId);
        const titleElement = Array.from(document.getElementsByClassName("title")).find(obj => obj.id == blogId);

        const change = Array.from(document.getElementsByClassName("fa fa-edit")).find(obj => obj.id == blogId);
        const save = Array.from(document.getElementsByClassName("fa fa-download")).find(obj => obj.id == blogId);
        const cancel = Array.from(document.getElementsByClassName("fa fa-cancel")).find(obj => obj.id == blogId);

        if(!renOpen){
            change.className = change.className + " d-none";
            save.className = save.className.replace(" d-none", "")
            cancel.className = cancel.className.replace(" d-none", "")
            textElement.contentEditable = true;
            titleElement.contentEditable = true;

            setRenOpen(true)
        } else {
            cancel.className = cancel.className + " d-none";
            save.className = save.className + " d-none";
            change.className = change.className.replace(" d-none", "")
            textElement.contentEditable = false;
            titleElement.contentEditable = false;

            setRenOpen(false)
        }
    };
    const saveChanges = async (blogId) => {
        let entry = renoveringar.find(obj => obj.id == blogId);
        const newText = Array.from(document.getElementsByClassName("text")).find(obj => obj.id == blogId).innerHTML;
        const newTitle = Array.from(document.getElementsByClassName("title")).find(obj => obj.id == blogId).innerHTML;
        entry.content = newText;
        entry.title = newTitle;
        try{
            const res = await axios.post(
                `${APIEnv.apiUrl}/api/blogs/changepost`,
                {
                  Salt: token,
                  BlogEntries: JSON.stringify([entry]),
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );
              alert("Ändringar sparade!")
        }catch{
            console.log("Failed to post blogentries.");
        }
    };

    return (

        <>
            {loading ? <></> : <>
                <div className="renoveringarContainer">
                    {/*      image row */}
                    <div className="col-12">
                        <div className="row imageRow">
                            <img src='/images/HR bilder/headers/Renoveringar.jpg' />
                        </div>
                    </div>

                    {/*  info */}
                    <div className="row">
                        <div className="col-md-6 p-md-5 p-5 pb-0">

                            <div className="ruleText d-flex justify-content-between">
                                <p className="title"><b>Genomförda renoveringar</b></p>
                                {user?.isAdmin ? (
                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                    <button className='yesButton mb-2 mb-md-0' onClick={newRen}>Ny renovering</button>
                                </div>
                            ) : (
                                <></>
                            )}
                            </div>


                            <div className="ruleText mt-3">
                                {renoveringar.map(blog => (<div>
                                    <div className='d-flex justify-content-between'>
                                    <p className="title" id={blog.id} style={{fontWeight: "bold"}}>{blog.title}</p>
                                    {user?.isAdmin ? <>
                                        <i style={{cursor: "pointer"}} className='fa fa-times' onClick={() => removeRen(blog.id)}></i>
                                        <i id={blog.id} style={{cursor: "pointer"}} className='fa fa-edit' onClick={() => editRen(blog.id)}></i>
                                        <i id={blog.id} style={{cursor: "pointer"}} className='fa fa-download d-none' onClick={() => saveChanges(blog.id)}></i>
                                        <i id={blog.id} style={{cursor: "pointer"}} className='fa fa-cancel d-none' onClick={() => editRen(blog.id)}></i>
                                    </>: <></>}


                                    </div>
                                    
                                    <p className="text bread" id={blog.id}>{blog.content}</p>
                                   
                                </div>))}

                                    
                            </div>
                        </div>
                        <div className="col-md-6 p-md-5 p-5 pt-4">

                            <div className="ruleText">
                                <p className="title newTitle title1"><b>Planerade kommande renoveringar</b></p>
                                <p className="newText bread text1"><b>Planerade kommande renoveringar</b></p>
                            </div>
                            <div className="ruleText mt-3">
                                <p className="title newTitle title2"><b>Renovering 1</b></p>
                                <p className="newText text2 bread">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                    xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                    Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                    xxxxx x. Xxx xxx x xxxxx xxx.</p>
                            </div>
                            <div className="ruleText mt-3">
                                <p className="title newTitle title3"><b>Renovering 1</b></p>
                                <p className="newText text3 bread">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                    xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                    Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                    xxxxx x. Xxx xxx x xxxxx xxx.</p>
                            </div>
                            <div className="ruleText mt-3">
                                <p className="title newTitle title4"><b>Renovering 1</b></p>
                                <p className="newText text4 bread">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                    xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                    Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                    xxxxx x. Xxx xxx x xxxxx xxx.</p>
                            </div>
                            <div className="ruleText mt-3">
                                <p className="title newTitle title5"><b>Renovering 1</b></p>
                                <p className="newText text5 bread">Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x. Xxx xxx x
                                    xxxxx xxx. Xxxx xx xxxx xxxx xxxx x xxxxx xxxx xxxx x xxxxx x.
                                    Xxx xxx x xxxxx xxx. Xxxx xxxxx xxxx xxxx x xxxxx xxxx xxxx x
                                    xxxxx x. Xxx xxx x xxxxx xxx.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>}

        </>
    )
}