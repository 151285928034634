import axios from 'axios';
import React, { useEffect, useState } from 'react';
import APIEnv from "./APIEnv";
const BlogPost = ({ id, index, title, content, date, poster, color, imgpath }) => {
    let fontColor = "white";
    if(["#f5f0e6", "#e1e6c8"].includes(color)){
        fontColor = "black"
    }
    const isSmallScreen = window.innerWidth < 768;
    useEffect(() => {
       // document.getElementById(id).innerHTML = content.replace(/\n/g, "<br>")
    },[]);
    return (
        <>
            <div className="row d-flex pictureRow">
                {index % 2 == 0 && !isSmallScreen? 
                <>
                <div className="col-md-6 d-flex p-0">
                    <div className="textContainer" style={{ backgroundColor: color, color: fontColor }}>
                        <h5 className='blogTitle' id={id}>{title}</h5>
                        <p className="mt-4 second-paragraph blogText" id={id} dangerouslySetInnerHTML={{ __html: content }}>
                        </p>
                      {/*   <i className="dateName">{poster} {date}</i> */}
                    </div>
                </div>
                <div className="col-md-6 sectionImage p-0">
                <img src={`${APIEnv.apiUrl}/api/blogs/getimage?imagePath=${encodeURIComponent(imgpath)}`}/>
                </div>
                </>  
                : 
                <>
                                <div className="col-md-6 sectionImage p-0">
                <img className="responsive" src={`${APIEnv.apiUrl}/api/blogs/getimage?imagePath=${encodeURIComponent(imgpath)}`} />
                </div>

                <div className="col-md-6 d-flex p-0">
                    <div className="textContainer" style={{ backgroundColor: color, color: fontColor }}>
                        <h5  className='blogTitle' id={id}>{title}</h5>
                        <p className="mt-4 second-paragraph blogText" id={id} dangerouslySetInnerHTML={{ __html: content }}>
                        </p>
                 {/*        <i className="dateName">{poster} {date}</i> */}
                    </div>
                </div>

                </>
                }
               
            </div>
        </>
    )
};
export default BlogPost;