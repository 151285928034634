export default function Footer() {

    return (
        <>
            <div className="footer">
                <div className="row p-5 pt-4 pb-3">
                    <div className="col-md-4 footerText">
                        <p className="mt-md-2">® Bostadsrättsföreningen Strindbergshuset</p>
                        <p>Karlavägen 82 / Karlaplan 10</p>
                        <p>115 22 Stockholm</p>
                    </div>
                    <div className="col-md-4 footerText text-center">
                 
                        <p className="mt-md-2">medlem@strindbergshuset.se</p>
                        <p>ekonomi@strindbergshuset.se</p>
                        <p>fastighet@strindbergshuset.se</p>
                        <p>it@strindbergshuset.se</p>
                        </div>
                    <div className="col-md-4 col-12 footerText container-fluid" style={{borderRight: 0}}>
                    Brf Strindbergshuset är en äkta bostadsrättsförening som registrerades 1989 och förvärvade byggnaden 2003. Föreningen upplåter 96 lägenheter med bostadsrätt samt 3 lägenheter och 5 lokaler med hyresrätt samt ett garage i två plan.</div>
                </div>
            </div>
        </>
    )
}