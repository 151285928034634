import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
import APIEnv from "../Components/APIEnv";
import axios from 'axios';
import React, { useState, useEffect } from 'react';
export default function Meetings(){
    VerifyUser(false);
    renderPageText("meetings");

    const downloadStamma = async () => {
        try{

            const res = await axios.get(`${APIEnv.apiUrl}/getstamma`, {
                responseType: 'blob' // important
            });
            
            // Create a blob link to download the file
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "protokoll.pdf");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        } catch(e){
            console.log(e)
        }
    };

    const uploadStamma = async (event) => {
        document.getElementById('fileInput').click();
    };

    const fileUpload = async (event) => {

        const file = event.target.files[0];
        if (file) {
            try {
                const formData = new FormData();
                formData.append("file", file);
                const res = await axios.post(`${APIEnv.apiUrl}/uploadstamma`, formData);
                alert("Årstämman uppladdad!")
                window.location.reload()
            } catch(e){
                console.log(e);
            }
        }
    }

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    useEffect(() => {
        const fetchUsers = async (token) => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${token}`);

                setUser(res.data);
            } catch (e) {
                console.log(e)
            }
        };

        VerifyUser(false).then((res) => {
            setToken(res.token);

            return res.token
        }).then((res) => {
            if (res) {
                fetchUsers(res)
            }
        })
    }, [token]);


    return (<>
        <div className="meetingContainer">
               {/*      image row */}
               <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/Årsstämma.jpg' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 p-md-5 p-5 pb-0">
                       {/*  <p style={{fontSize: "28px"}}>Årsstämma</p> */}
                        Protokoll från senaste stämman
                        <i className='fa fa-download m-3' onClick={downloadStamma}></i>
                        {user && user.isAdmin ? <>
                        <br></br>
                            Ladda upp nytt protokoll
                        <i className='fa fa-upload m-3' style={{cursor: "pointer"}} onClick={uploadStamma}></i>
                        <input type="file" id="fileInput" style={{display: "none"}} accept=".pdf" onChange={fileUpload}/>
                        </> : <></>}
                        <div className="ruleText mt-5">
                            <p className="title newTitle title1"><b></b></p>
                            <p className="bread newText text1"></p>
                        </div>

                    </div>

                </div>
        </div>
        </>)
}