import { useEffect } from "react";
import axios from "axios"; // Ensure axios is imported
import APIEnv from '../Components/APIEnv';

export default function Uploads() {
    useEffect(() => {
        const getUploads = async () => {
            try {
                const res = await axios.get(
                    `${APIEnv.apiUrl}/getuploads`,
                    {
                        responseType: 'blob' // Important to handle binary data
                    }
                );

                // Create a URL for the file
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;

                // Set the download attribute to prompt for download
                link.setAttribute('download', 'uploads.zip');

                // Append to the document and click to trigger download
                document.body.appendChild(link);
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url); // Clean up the URL object
            } catch (e) {
                console.log("Failed to get uploads", e);
            }
        };
        getUploads();
    }, []);

    return (<></>);
}
