import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
export default function Contact(){
    VerifyUser(false);
    renderPageText("contact")
    const isSmallScreen = window.innerWidth < 768;
    return (
        <>
        <div className="contactContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img  style={{width: "120vw"}} src='/images/HR bilder/Headers/Kontakt.jpg' />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 p-5 pt-1" style={{backgroundColor: "#f5f0e6"}}>
                {/*         <p style={{fontSize: "28px"}}>Kontakt</p> */}
                        
                        <div className="ruleText mt-5">
                            <p className="title newTitle title1"><b></b></p>
                            <p className="bread newText text1"></p>
                        </div>

                    </div>
                    <div className='col-md-6 p-5 pt-md-1 pt-5'  style={{backgroundColor: "#e6d6b8"}}>
                        {!isSmallScreen ? <p style={{fontSize: "28px"}}>&nbsp;</p> : <></> }
                

                    <div className="ruleText">
                            <p className="title newTitle title2"><b></b></p>
                            <p className="bread newText text2"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title3"><b></b></p>
                            <p className="bread newText text3">df</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title4"><b></b></p>
                            <p className="bread newText text4"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title5"><b></b></p>
                            <p className="bread newText text5"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title6"><b>t</b></p>
                            <p className="bread newText text6"></p>
                        </div>
                    </div>

                </div>
        </div>
        </>
    )
}