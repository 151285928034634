import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BlogPost from '../Components/BlogPost'
import CreatePost from '../Components/CreatePost';
import APIEnv from "../Components/APIEnv";
import VerifyUser from '../Components/VerifyUser';

export default function Home() {

    const [blogEntries, setBlogEntries] = useState([]);
    const [deleteOpen, setDelete] = useState(false);
    const [showEdit, setEdit] = useState(false);
    const [buttonText, setButtonText] = useState("Ändra inlägg");
    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(true)

    const showPost = () => {
        document.getElementsByClassName("createPostContainer")[0].style.opacity = 1;
        document.getElementsByClassName("createPostContainer")[0].style.pointerEvents = "unset";
    }

    const deleteThis = (blogId) => {
        const popUpWindow = document.getElementsByClassName("popUpContainer")[0];
        popUpWindow.innerHTML = ""
        popUpWindow.style.opacity = 1;
        popUpWindow.style.pointerEvents = "unset";

        // Create the first div with class "row"
        var row1 = document.createElement("div");
        row1.className = "row";

        // Create the first nested div with class "col-12" and text content
        var col12 = document.createElement("div");
        col12.className = "col-12";
        col12.textContent = "Vill du radera inlägget?";

        // Append the nested div to the first div
        row1.appendChild(col12);

        // Create the second div with class "row" and additional class "mt-3"
        var row2 = document.createElement("div");
        row2.className = "row mt-3";

        // Create the first nested div with class "col-8"
        var col8 = document.createElement("div");
        col8.className = "col-6";

        // Create a button with class "btn btn-primary deletePostButton" and text content
        var deleteButton = document.createElement("button");
        deleteButton.className = "deletePostButton";
        deleteButton.textContent = "Radera";
        deleteButton.addEventListener("click", async () => {
            try {
                const res = await axios.post(`${APIEnv.apiUrl}/api/blogs/deleteblog`, {
                    id: blogId,
                    userId: token
                });
                alert("Inlägget raderat!");
                
                    window.location.reload();
               
            } catch (e) {
                alert(e.message)
            }
        });

        // Append the button to the first nested div
        col8.appendChild(deleteButton);

        // Append the first nested div to the second div
        row2.appendChild(col8);

        // Create the second nested div with class "col-4"
        var col4 = document.createElement("div");
        col4.className = "col-6";

        // Create a button with class "btn btn-secondary deletePostButton" and text content
        var cancelButton = document.createElement("button");
        cancelButton.className = "deletePostButton";
        cancelButton.textContent = "Avbryt";
        cancelButton.addEventListener("click", function () {
            popUpWindow.style.opacity = 0;
            popUpWindow.style.pointerEvents = "none";
            setTimeout(() => {
                popUpWindow.innerHTML = "";
            }, 500)
        });

        // Append the button to the second nested div
        col4.appendChild(cancelButton);

        // Append the second nested div to the second div
        row2.appendChild(col4);

        // Append both divs to the document body or any other parent element
        popUpWindow.appendChild(row1);
        popUpWindow.appendChild(row2);

    }

    const editPost = async () => {
        setEdit(!showEdit);
        if(showEdit){
            const titleElement = document.getElementsByClassName("blogTitle");
            const textElement = document.getElementsByClassName("blogText");
            let i = 0;
            while(true) {
                const blogEntry = blogEntries.find(obj => obj.id == titleElement[i]?.id);
                if(blogEntry){
                    blogEntry.title = titleElement[i]?.innerHTML;
                    blogEntry.content = textElement[i]?.innerHTML;
                    const blogEntriesIndex = blogEntries.findIndex(obj => obj.id == blogEntry.id);
                    blogEntries[blogEntriesIndex] = blogEntry;
                    setBlogEntries(blogEntries);
                    if(titleElement[i]){
                        titleElement[i].innerHTML =blogEntry.title
                    }
                    if(textElement[i]){
                        textElement[i].innerHTM = blogEntry.content
                    }
                }
                if(!titleElement[i] && !textElement[i]){
                    break;
                }
                i++;
            }

            try{
                const res = await axios.post(
                    `${APIEnv.apiUrl}/api/blogs/changepost`,
                    {
                      Salt: token,
                      BlogEntries: JSON.stringify(blogEntries),
                    },
                    {
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    }
                  );
                  alert("Ändringar sparade!")
            }catch{
                console.log("Failed to post blogentries.");
            }
        }
    }
    const openDelete = () => {
        const deletePop = document.getElementsByClassName("deleteDropDown")[0];

        deletePop.style.height = "auto";
        deletePop.style.maxHeight = "500px";

    }
    const closeDelete = () => {
        const deletePop = document.getElementsByClassName("deleteDropDown")[0];
        deletePop.style.maxHeight = "0";
    }

    useEffect(() => {

        if(showEdit){
            setButtonText("Spara ändringar");

        } else {
            setButtonText("Ändra inlägg");
        }

        const titleElement = document.getElementsByClassName("blogTitle");
        const textElement = document.getElementsByClassName("blogText");
        for (let i = 0; i < titleElement.length || i < textElement.length; i++) {
            titleElement[i]?.setAttribute("contentEditable", showEdit ? "true" : "false");
            textElement[i]?.setAttribute("contentEditable", showEdit ? "true" : "false");
            if (!titleElement[i] && !textElement[i]) {
                break;
            }
        }
    }, [deleteOpen, showEdit]);

    useEffect(() => {
        async function fetchData() {
            let attempts = 5;
            let current = 0;
            while (attempts > current) {
                try {
                    const res = await axios.post(`${APIEnv.apiUrl}/api/pagecontent`, {
                        page: "home",
                        content: "",
                        section: 0
                    });
                    const pageTexts = res.data.entries;
                    const blogEntries = res.data.matchingBlogEntries;
                    setBlogEntries(blogEntries.reverse())
                    for (const text of pageTexts) {
                        const elementTextClass = "text" + text.section;
                        const elementTitleClass = "title" + text.section;
                        const textElement = document.getElementsByClassName(elementTextClass)[0];
                        const titleElement = document.getElementsByClassName(elementTitleClass)[0];
                        const regex = /<title>([\s\S]*?)<\/title>[\s\S]*?<text>([\s\S]*?)<\/text>/;
                        const match = text.content.match(regex);
                        const titleText = match[1].trim();
                        const bodyText = match[2].trim();

                        if (textElement) {
                            textElement.innerHTML = bodyText;
                            textElement.setAttribute("id", text.id);
                        }
                        if (titleElement) {
                            titleElement.innerHTML = titleText;
                            titleElement.setAttribute("id", text.id);
                        }
                    }

                    break;
                } catch {
                    const textElement = document.getElementsByClassName("newText");
                    const titleElement = document.getElementsByClassName("newTitle");
                    for (const text of textElement) {
                        text.innerHTML = `Xxxxx xxxx xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx
                        xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx xxxx. Xxxxx xxxx xxxx`
                    }
                    for (const title of titleElement) {
                        title.innerHTML = "Placeholder Title"
                    }
                    current++;
                }
            }
        }
        fetchData();
    }, [])

    const redirect = (path) => {
        window.location.href = window.location.href.replace("home", "") + path
    }

    const [user, setUser] = useState([]);
    useEffect(() => {
        const fetchUsers = async (token) => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${token}`);
                setUser(res.data);
            } catch (e) {
                console.log(e);
            }
        };
    
        VerifyUser(false).then((res) => {
            setToken(res.token);
            setLoading(false)
            setTimeout(() => {
                if(token){
                    fetchUsers(res.token);
                }
            }, 100);
        });
    }, [token]);
    

    return (
        
        <>
        {loading ? <></> : <CreatePost currentPage={"home"} token={token} />}
         
        <div id="href" path="http://strindbergshuset.se/" style={{display: "none"}}></div>
            {/* welcome image */}
            <div className="homePageContainer">
                <div className="col-12">
                    <div className="row imageRowHome">
                        <img src='/images/HR bilder/startcrop.png' />
                    </div>
                </div>

                {/* circlerow */}
                <div className="row circleRow">
                    <div className="col-md-4 circleRow" >
                        <div className="row  d-flex justify-content-center">
                            <img style={{cursor: "pointer"}} src='/images/ikoner/om.svg'  onClick={() => redirect("history")}/>
                        </div>
                        <div className="row text-center mt-3 circleText p-4 pt-0">
                            <h5 className='title1 newTitle'></h5>
                            <p className='text1 newText'></p>
                        </div>

                    </div>
                    <div className="col-md-4 circleRow" >
                        <div className="row d-flex justify-content-center">
                            <img style={{cursor: "pointer"}} src='/images/ikoner/Regler.svg'  onClick={() => redirect("rules")}/>
                        </div>
                        <div className="row text-center mt-3 circleText p-4 pt-0">
                            <h5 className='title2 newTitle' contentEditable="true"></h5>
                            <p className='text2 newText' contentEditable="true"></p>
                        </div>
                    </div>
                    <div className="col-md-4 circleRow" >
                        <div className="row  d-flex justify-content-center" >
                            <img style={{cursor: "pointer"}} src='/images/ikoner/Kontakt_1.svg' onClick={() => redirect("contact")}/>
                        </div>
                        <div className="row text-center mt-3 circleText p-4 pt-0">
                            <h5 className='title3 newTitle' contentEditable="true"></h5>
                            <p className='text3 newText' contentEditable="true"></p>
                        </div>
                    </div>
                </div>


                {token ?
                    <>
                    {user.isAdmin ? <>
                        <div className='row'>
                        <div onClick={showPost} className="createPost container-fluid text-center pt-1">Nytt inlägg&nbsp;&nbsp;&nbsp;
                            <i className="fa fa-plus"></i></div>

                        <div onClick={openDelete} onMouseEnter={openDelete} onMouseLeave={closeDelete} className="createPost container-fluid text-center pt-1">Ta bort inlägg&nbsp;&nbsp;&nbsp;
                            <i className="fa fa-times"></i></div>

                        <div className='deleteDropDown p-0'>
                            {blogEntries.map((blog) => (
                                <div className='deleteSelect' onMouseEnter={openDelete} onMouseLeave={closeDelete} onClick={() => {
                                    deleteThis(blog.id);
                                }}>{blog.title}</div>
                            ))}
                        </div>

                        <div onClick={editPost} className="createPost container-fluid text-center pt-1 changeBlog">{buttonText}&nbsp;&nbsp;&nbsp;
                            <i className="fa fa-edit"></i>
                        </div>

                    </div>
                    </> : <>
                    </>}
                

                    </>
                    : <></>}

                {blogEntries.map((blog, index) => (
                    <BlogPost
                        id={blog.id}
                        index={index}
                        title={blog.title}
                        content={blog.content}
                        poster={blog.posterName}
                        date={blog.dateposted.split("T")[0]}
                        color={blog.color}
                        imgpath={blog.imagePath}
                    />
                ))}
            </div>
        </>
    )
}