import React, { useEffect, useState } from 'react';
import axios from 'axios';
import APIEnv from "./APIEnv";
export default function CreateAccount() {
  const [formData, setFormData] = useState({
   name: "",
   email: "",
   password:"",
   password2: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const postBlog = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.email)
    
    const numberRegex = /\d/;
    const isValidPassword = numberRegex.test(formData.password)

    // Access formData.title and formData.content to send data to the server
    if(formData.name == ""){
        alert("Du måste ange ett namn.")
        return
    } else if(!isValidEmail){
        alert("Email är i fel format.")
        return
    } else if(7 > formData.password.length) {
        alert("Lösenordet måste vara minst 7 tecken.")
        return
    } else if(!isValidPassword){
        alert("Lösenordet måste innehålla siffror.")
        return
    } else if (formData.password != formData.password2){
        alert("Lösenorden matchar inte.")
        return
    }
    try {
      const res = await axios.post(`${APIEnv.apiUrl}/api/user/newuser`, formData);
        alert(res.data.message)
        setFormData({
            name: "",
            email: "",
            password:"",
            password2: ""
           })
           window.location.reload()
    } catch (error) {
      console.error();
    }
  };


  const hidePost = () => {
    document.getElementsByClassName("createAccount")[0].style.opacity = 0;
    document.getElementsByClassName("createAccount")[0].style.pointerEvents  = "none";
  }
  return (
    <div className='d-flex justify-content-center'>
    <div className="createAccount p-5 pt-3 pb-3 col-xxl-4 col-md-6 col-sm-8 col-11">
      <form onSubmit={postBlog}>

        <div className='row formRow'>
        
          Namn:
          <input className="mb-3 form-control" type="text" name="name" value={formData.name} onChange={handleInputChange} />
          E-mail:
          <input className="mb-3 form-control" type="text" name="email" value={formData.email} onChange={handleInputChange} />
          Lösenord:
          <input className="mb-3 form-control" type="password" name="password" value={formData.password} onChange={handleInputChange} />
          Repetera lösenord:
          <input className="mb-3 form-control" type="password" name="password2" value={formData.password2} onChange={handleInputChange} />
        </div>

       <div className='d-lg-flex justify-content-around'>
       <button type="submit" className='mt-4 p-1 text-center createUserButton'>Skapa användare</button>
       <div className='mt-4 p-1 formRowButton text-center' onClick={hidePost}>Avbryt</div>
       </div>
        

      </form>
    </div>
    </div>
  );
}
