import React, { useState, useEffect } from 'react';
import axios from 'axios';
import APIEnv from "./APIEnv";

export default async function VerifyUser(redirectUser) {
   
        const storedToken = localStorage.getItem("bc7f398893fc");
        let current = 0;

        if(storedToken){
            while (true) {
                try {
                    const res = await axios.post(`${APIEnv.apiUrl}/user/verify?salt=${storedToken}`);
                    if (res.status == 200) {
                        return {token: storedToken, verified: true};
                    }
                } catch (error) {
                    console.log("Failed to verify user.");
                    current++;
                }
                if(current == 5){
                    localStorage.removeItem("bc7f398893fc")
                if(redirectUser){
                    window.location.href="/"
                }
                    return {token: false, verified: false}
                }
            }
        } else {
            return {token: null, verified: false}
        }
}