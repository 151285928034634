import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
import axios from 'axios';
import APIEnv from '../Components/APIEnv';
export default function Rules() {
    VerifyUser(false);
    renderPageText("rules");

    const downloadFile = async (type) => {
        try {
            const res = await axios.get(`${APIEnv.apiUrl}/getrules?type=${type}`, {
                responseType: 'blob' // important
            });
            
            // Create a blob link to download the file
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${type}.pdf`); // Ensure the file is downloaded as a .pdf
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <>
            <div className="ruleContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/Header regler och stadgar.jpg' />
                    </div>
                </div>
                <p className='mt-4 text-center pb-2'>Vi vill alla trivas och vi vill ha lätt åtkomst till de regler som gäller för renovering och ombyggnation.
Här finns dessutom föreningens stadgar. Klicka på nedan symbol för nedladdning av respektive dokument.</p>

               {/*  rules download */}
                <div className="col-12 renoveringar p-md-5 pt-5 p-0 pb-5">
                    <div className="ruleDownloadContainer">
                        <p style={{ paddingRight: "20px" }}>
                            <i style={{ color: "#96321e" }}><b>Trivselregler</b></i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }} onClick={() => downloadFile("Trivselregler Strindbergshuset 240612")}></i>
                        </p>
                        <div style={{ borderBottom: "1px solid black" }}></div>
                        <p className="mt-2" style={{ paddingRight: "20px" }}>
                            <i style={{ color: "black" }}>Regler vid ombyggnad/renovering</i>
                            <i className="fa fa-download mt-2" style={{ float: "right", }} onClick={() => downloadFile("Regler vid ombyggnad och renovering")}></i>
                        </p>
                        <div style={{ borderBottom: "1px solid black" }}></div>
                        <p className="mt-2" style={{ paddingRight: "20px" }}>
                            <i style={{ color: "black" }}>Detaljerade regler</i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }} onClick={() => downloadFile("detaljerade regler 2024-01-06")}>
                            </i>
                        </p>
                        <div style={{ borderBottom: "1px solid black" }}></div>
                        <p className="mt-2" style={{ paddingRight: "20px" }}>
                            <i style={{ color: "black" }}>Stadgar</i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }} onClick={() => downloadFile("stadgar")}>
                            </i>
                        </p>

                    </div>
                    <p></p>
                </div>
            </div>
        </>
    )
};