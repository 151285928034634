import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
import { useState, useEffect } from 'react';
import axios from 'axios';
import APIEnv from '../Components/APIEnv';

export default function Redovisningar(){

    renderPageText("redovisningar");

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    useEffect(() => {
        const fetchUsers = async (token) => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${token}`);

                setUser(res.data);
            } catch (e) {
                console.log(e)
            }
        };

        VerifyUser(false).then((res) => {
            setToken(res.token);

            return res.token
        }).then((res) => {
            if (res) {
                fetchUsers(res)
            }
        })
    }, [token]);

    const addRedovisning = async () => {
        document.getElementById('fileInput').click();
    }


    const fileUpload = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            try {
                const formData = new FormData();
                formData.append("file", file);
                const res = await axios.post(`${APIEnv.apiUrl}/redovisningupload`, formData);
                alert("Årsredovisningen uppladdad!");
                window.location.reload();
            } catch(e){
                console.log(e);
            }
        }
    };

    const [pdfs, setPdfs] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try{
                const res = await axios.get(`${APIEnv.apiUrl}/redovisningar`);
              
                setPdfs(res.data)
            } catch(e){

            }
        };
        fetchData()
    },[])

    const download = async (pdf) => {
        try {
            const res = await axios.get(`${APIEnv.apiUrl}/getredovisning?fileName=${pdf}`, {
                responseType: 'blob' // important
            });
            
            // Create a blob link to download the file
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pdf.split("\\").pop());
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };
    const deletePdf = async (pdf) => {
        const popUpWindow = document.getElementsByClassName("popUpContainer")[0];
        popUpWindow.innerHTML = ""
        popUpWindow.style.opacity = 1;
        popUpWindow.style.pointerEvents = "unset";

        // Create the first div with class "row"
        var row1 = document.createElement("div");
        row1.className = "row";

        // Create the first nested div with class "col-12" and text content
        var col12 = document.createElement("div");
        col12.className = "col-12";
        col12.textContent = "Vill du radera redovisningen?";

        // Append the nested div to the first div
        row1.appendChild(col12);

        // Create the second div with class "row" and additional class "mt-3"
        var row2 = document.createElement("div");
        row2.className = "row mt-3";

        // Create the first nested div with class "col-8"
        var col8 = document.createElement("div");
        col8.className = "col-6";

        // Create a button with class "btn btn-primary deletePostButton" and text content
        var deleteButton = document.createElement("button");
        deleteButton.className = "deletePostButton";
        deleteButton.textContent = "Radera";
        deleteButton.addEventListener("click", async () => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/deleteredovisning?fileName=${pdf}`);
                alert("Redovisning raderad!");
              
                window.location.reload();
            
            } catch (e) {
                alert(e.message)
            }
        });

        // Append the button to the first nested div
        col8.appendChild(deleteButton);

        // Append the first nested div to the second div
        row2.appendChild(col8);

        // Create the second nested div with class "col-4"
        var col4 = document.createElement("div");
        col4.className = "col-6";

        // Create a button with class "btn btn-secondary deletePostButton" and text content
        var cancelButton = document.createElement("button");
        cancelButton.className = "deletePostButton";
        cancelButton.textContent = "Avbryt";
        cancelButton.addEventListener("click", function () {
            popUpWindow.style.opacity = 0;
            popUpWindow.style.pointerEvents = "none";
            setTimeout(() => {
                popUpWindow.innerHTML = "";
            }, 500)
        });

        // Append the button to the second nested div
        col4.appendChild(cancelButton);

        // Append the second nested div to the second div
        row2.appendChild(col4);

        // Append both divs to the document body or any other parent element
        popUpWindow.appendChild(row1);
        popUpWindow.appendChild(row2);

    }

    return (<>
    <div className="redovisningarContainer">
            {/*      image row */}
            <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/Årsredovisningar.jpg' />
                    </div>
                </div>
                <div className="col-12 renoveringar p-md-5 pt-5 p-0 pb-5">
                    {user && user.isAdmin? <>
             
                        <div className='d-flex mb-4 p-5 pb-0 pt-0 p-md-0'>
                        <p style={{fontSize: "14px"}}>Lägg till årsredovisning</p>
                        <input type="file" id="fileInput" style={{display: "none"}} accept=".pdf" onChange={fileUpload}/>

                    <i className='p-3  pt-1 fa fa-plus' style={{cursor: "pointer"}} onClick={addRedovisning}></i>
                    </div>
                    
                    </> : <></>}
                    <div className="ruleDownloadContainer">
                        {pdfs.map((pdf) => (<>
                            <p style={{ paddingRight: "20px" }} className='pt-3'>
                            <i style={{ color: "#96321e" }}><b>{pdf.split("\\").pop().replace(".pdf", "")}</b></i>
                            {user && user.isAdmin ? <>
                            <i className='fa fa-times m-3 mt-2' style={{ float: "right", cursor: "pointer" }} onClick={()=>deletePdf(pdf)}></i>
                            </> : <></>}
                            <i className="fa fa-download mt-2" style={{ float: "right" }} onClick={() => download(pdf)}></i>
                        </p>
                        <div style={{ borderBottom: "1px solid black" }}></div>
                        </>

                        ))}
{/*                         <p style={{ paddingRight: "20px" }}>
                            <i style={{ color: "#96321e" }}><b>Trivselregler</b></i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }}></i>
                        </p>
                        <div style={{ borderBottom: "1px solid black" }}></div>

                        <p className="mt-2" style={{ paddingRight: "20px" }}>
                            <i style={{ color: "black" }}>Regler vid ombyggnad/renovering</i>
                            <i className="fa fa-download mt-2" style={{ float: "right", }}></i>
                        </p>
                        <div style={{ borderBottom: "1px solid black" }}></div>

                        <p className="mt-2" style={{ paddingRight: "20px" }}>
                            <i style={{ color: "black" }}>Detaljerade regler</i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }}>
                            </i>
                        </p> */}

                    </div>
                    <p></p>
                </div>
     
    </div>
    </>)
}