import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
import APIEnv from '../Components/APIEnv';
import axios from 'axios';
export default function Maklare() {
    VerifyUser(false);
    renderPageText("maklare")

    const downloadFile = async (type) => {
        try {
            const res = await axios.get(`${APIEnv.apiUrl}/getrules?type=${type}`, {
                responseType: 'blob' // important
            });
            
            // Create a blob link to download the file
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${type}.pdf`); // Ensure the file is downloaded as a .pdf
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <>
            <div className="maklarContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/Mäklarinfo.jpg' />
                    </div>
                </div>
                <div className="col-12 renoveringar p-md-5 pt-5 p-0 pb-5">
                    <div className="ruleDownloadContainer">
                        <p style={{ paddingRight: "20px" }}>
                            <i style={{ color: "#96321e" }}><b>Mäklarinformation</b></i>
                            <i className="fa fa-download mt-2" style={{ float: "right" }} onClick={() => downloadFile("Mäklarinformation Strindbergshuset")}></i>
                        </p>


                        <div style={{ borderBottom: "1px solid black" }}></div>


                    </div>
                    <p></p>
                </div>
                {/*  text */}
                <div className="row">
                    <div className="col-md-6 p-md-5 pt-md-0 p-5 pt-0 pb-0">
                        <div className="ruleText mt-5">
                            <p className="title newTitle title1"><b>Allmänt</b></p>
                            <p className="bread newText text1" style={{ textAlign: "left" }}>Brf Strindbergshuset registrerades 1989 och förvärvade byggnaden
                                2003. Byggnadens totalyta är enligt taxeringsbeskedet 11 570 m²,
                                varav 8 742 m² utgör lägenhetsyta och 2 828 m² utgör lokalyta. Föreningen upplåter 96 lägenheter med bostadsrätt samt 2 lägenheter
                                och 5 lokaler med
                                hyresrätt.Föreningen har god ekonomi och har amorterat av XX
                                Mkr under 2022 och 2023.
                                <br></br>
                                <br></br>
                                <b>Adresser: </b>Karlaplan 10 och Karlavägen 82<br></br>
                                <b>Organisationsnummer: </b> 716419-2341
                                <br></br>
                                <b>Fastighetens byggnadsår: </b>1969-1971
                                <br></br>
                                <b>Antal lägenheter(bostadsrätter): </b> 98 varav 2 uthyrs
                                <br></br>
                                <b>Antal lokaler (för uthyrning): </b> 5 st
                                <br></br>
                                <b>Marken: </b>Friköpt
                                <br></br>
                               <b> Äkta/oäkta förening: </b>Äkta</p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title2"><b>I avgiften ingår:</b></p>
                        </div>
                        <div className="ruleText mt-3">

                            <p className="bread newText text2">
                                <ul>
                                <li>Värme</li>
                                
                                <li> Vatten<br></br></li>
                                <li>Tvättstuga i källaren med tre tvättmaskiner, torktumlare, torkskåp
                                och mangel. Nya maskiner installerades under januari 2022</li>
                                <li>Källarförråd<br></br></li>
                                <li>Cykelförvaring på gården<br></br></li>
                                <li> Barnvagnsrum, i entrén till Karlaplan 10.<br></br></li>
                                <li>TV genom Tele2 (fd Comhem), kanaler som ingår i basutbudet</li>
                                <li>1,2,3,4,8 och kunskapskanalen<br></br></li>
                                <li>Bredband genom Ownit, 1000 Mbit/s<br></br></li>
                                <li>Det finns även ett grovsoprum och ett återvinningsrum</li>
                                <li> Uppvärmning och ventilation</li>
                                </ul>
                                </p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title3"><b></b></p>
                            <p className="newText newText text3"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title4"><b></b></p>
                            <p className="bread newText text4"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title5"><b></b></p>
                            <p className="bread newText text5"></p>
                        </div>
                    </div>
                    <div className="col-md-6 p-md-5 pt-md-0 p-5 pt-0">
                        <p style={{ fontSize: "28px" }}>&nbsp;</p>
                        <div className="ruleText mt-5">
                            <p className="title newTitle title6"><b></b></p>
                            <p className="bread newText text6"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title7"><b></b></p>
                            <p className="bread newText text7"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title8"><b></b></p>
                            <p className="bread newText text8"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title9"><b></b></p>
                            <p className="bread newText text9"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title10"><b></b></p>
                            <p className="bread newText text10"></p>
                        </div>
                        <div className="ruleText mt-3">
                            <p className="title newTitle title11"><b></b></p>
                            <p className="bread newText text11"></p>
                        </div>
                    </div>
                </div>
            </div></>
    )
}