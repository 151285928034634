import PopUp from './PopUp';
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import APIEnv from "./APIEnv";
export default function DeleteUser({token}) {

    const [users, setUsers] = useState([]);
    useEffect(() => {
        const fetchUsers = async () => {
            try {

                const res = await axios.get(`${APIEnv.apiUrl}/user/allusers?salt=${token}`);
                const filteredUsers = res.data.filter(obj => obj.name !== "admin@strindbergshuset.se");
                const sortedUsers = filteredUsers.sort((a, b) => a.name.localeCompare(b.name));
                setUsers(sortedUsers);
            } catch (e) {
                console.log(e)
            }
        };
        fetchUsers();
    }, []);


    const closePop = () => {
        const popUp = document.getElementsByClassName("deleteUserContainer")[0];
        popUp.style.opacity = "0";
        popUp.style.pointerEvents = "none";
    }

    const [deleteUser, setDeleteUser] = useState("");
    const deleteSet = (e) => {
        setDeleteUser(e.target.value);
    };

    const ultDelete = async () => {
        if(deleteUser == "") return;
        const popUp = document.getElementsByClassName("deleteUserContainer")[0];
        const body = {
            saltId: deleteUser,
            salt: token
        }
        try {
            
            const res = await axios.post(`${APIEnv.apiUrl}/user/deleteuser`, body);
            alert("Användaren raderad!")
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
            window.location.reload()
        } catch (e) {
            console.log(e);
        }
    }

    return (<>
        <div className="deleteUserContainer pt-3 pb-3 d-flex flex-column align-items-center text-center">
            Radera användare
            <select className="deleteUserSelect mt-3 form-control w-75" onChange={deleteSet} >
                <option></option>
            {users.map((user) => {
          if (user.name !== "") {
            return (
              <option key={user.name} value={user.name}>
                {user.name}
              </option>
            );
          }
          return null; // You might want to handle cases where user.name is empty
        })}
            </select>
            <div className='col-md-8 col-11 mt-3 d-flex justify-content-between'>
                <button className="newUserButton submitButton m-3" onClick={ultDelete}>
                    Radera användare
                </button>
                <button className='newUserButton m-3' onClick={closePop}>Avbryt</button>
            </div>
        </div>
    </>)
}