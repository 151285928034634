import CreatePost from '../Components/CreatePost';
import { useEffect, useState } from 'react';
import ForumBlogPost from '../Components/ForumBlogPost';
import APIEnv from '../Components/APIEnv';
import axios from 'axios';
import VerifyUser from '../Components/VerifyUser';

export default function Forum() {
    
    const [token, setToken] = useState(null)
    const [blogEntries, setBlogEntries] = useState([]);
    const [myBlogs, setMyBlogs] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchData = async () => {
            let attempts = 5;
            let current = 0;
            while (attempts > current) {
                try {
                    const res = await axios.post(`${APIEnv.apiUrl}/api/pagecontent`, {
                        page: "forum",
                        content: "",
                        section: 0
                    });
                    const blogEntries = res.data.matchingBlogEntries;
                    setBlogEntries(blogEntries.reverse());
                    break;
                } catch (err) {
                    console.log(err);
                }
            }
        }
        const fetchMyBlogs = async (newToken) => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/blogs/getmyposts?salt=${newToken}`);
                setMyBlogs(res.data);

                setLoading(false);
            } catch (e) {
                console.log("ERRR", e);
            }
        };
        VerifyUser(true).then((res) => {
            setToken(res.token)
            if(!res.token){
                window.location.href="/"
            }
            return res.token
        }).then((newToken) => {
            fetchData()
            return newToken
        }).then((ultToken) => {
            fetchMyBlogs(ultToken)
        })
    },[token])

    const newPostPop = () => {
        document.getElementsByClassName("createPostContainer")[0].style.opacity = 1;
        document.getElementsByClassName("createPostContainer")[0].style.pointerEvents = "unset";
         document.getElementsByClassName("createPostContainer")[0].className = document.getElementsByClassName("createPostContainer")[0].className + " text-center"
     /*   document.getElementsByClassName("createPostContainer")[0].innerHTML = "Nytt inlägg" + document.getElementsByClassName("createPostContainer")[0].innerHTML */
    };
   
    return (
        <>
        {loading ? <></> : <>
        <CreatePost currentPage={"forum"} token={token}/>
        </>}
            
            {token ? (
                <div className="forumContainer pb-4">
                    {/* image row */}
                    <div className="col-12">
                        <div className="row imageRow">
                            <img src='/images/april/Sidhuvud Anslagstavlan.jpg' />
                        </div>
                    </div>
                    <div className="mt-3 text-center" style={{ fontSize: "15px" }}>
                        Välkommen till föreningens anslagstavla!<br /> Här kan du göra personliga inlägg som du delar med andra
                        inloggade medlemmar.<br /> Inläggen är inte anonyma.
                    </div>

                    <div className='row'>
                        <div className="createPost container-fluid text-center pt-1 mt-3" style={{ borderRadius: "5px" }} onClick={newPostPop}>Nytt inlägg&nbsp;&nbsp;&nbsp;
                            <i className="fa fa-plus"></i></div>
                    </div>

                    {loading ? (
                        <div>Loading...</div> // Render a loading indicator while data is being fetched
                    ) : (
                        blogEntries.map((blog, index) => (
                            <ForumBlogPost
                                key={blog.id}
                                index={index}
                                id={blog.id}
                                title={blog.title}
                                content={blog.content}
                                poster={blog.posterName}
                                date={blog.dateposted.split("T")[0]}
                                myblogs={myBlogs}
                                token={token}
                                numberlikes={blog.likes}
                                likes={blog.likedBy}
                            />
                        ))
                        
                    )}      
        
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
}
