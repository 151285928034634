import { useEffect, useState } from "react"
import APIEnv from "./APIEnv"
import axios from "axios"
export default function CommentWindow({ id, index, token, comments }) {

    const [formData, setFormData] = useState({
        content: "",
        user: token,
        postid: id
    })
    const postComment = async () => {
        try {
            const res = await axios.post(`${APIEnv.apiUrl}/blogs/postcomment`, formData);
            alert("Kommentaren publicerades!")
            closeCommentPop()
            setFormData({
                ...formData,
                content: ""
            })
            document.getElementsByClassName("commentText")[0].value = ""
            window.location.reload()
        } catch (e) {
            console.log(e)
        }
    }
    const updateFormData = (e) => {
        setFormData({
            ...formData,
            content: e.target.value
        })
    }
    const closeCommentPop = () => {
        const commentButtons = Array.from(document.getElementsByClassName("commentCon"))
        commentButtons.forEach(element => {
            element.style.pointerEvents = "unset"
        });
        const commentPop = document.getElementsByClassName("commentPop")[index]
        commentPop.style.opacity = 0
        commentPop.style.pointerEvents = "none"
    }
    const showDeleteComment = (commentid) => {
        const popUp = document.getElementsByClassName("popUpContainer")[0]
        popUp.innerHTML = ""
        popUp.style.opacity = "1";
        popUp.style.pointerEvents = "unset";
        popUp.append("Radera kommentaren?")

        const buttonDiv = document.createElement("div")
        buttonDiv.className = "d-flex"

        const yesButton = document.createElement("button")
        yesButton.innerHTML = "Radera kommentar"
        yesButton.className = "yesButton m-2"

        const noButton = document.createElement("button")
        noButton.innerHTML = "Avbryt"
        noButton.className = "noButton m-2"

        noButton.addEventListener("click", () => {
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
        })
        yesButton.addEventListener("click", async () => {
            try{
                const res = await axios.post(`${APIEnv.apiUrl}/blogs/deletecomment`, {
                    commentid: commentid,
                    userId: token
                })
                alert("Kommentaren raderad!")
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            } catch(err){
                console.log(err)
            }
        })
        buttonDiv.append(yesButton)
        buttonDiv.append(noButton)
        popUp.append(buttonDiv)
    }
    
    const[user, setUser] = useState(null)
    useEffect(() => {
        const fetchUser = async () => {
            const userRes = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${token}`);
            setUser(userRes.data.isAdmin)
        }
        fetchUser()
    },[])
    return <>
        <div className='commentPop p-4'>
            <i className='fa fa-times commentTimes' style={{ right: "20px", top: "5px", position: "absolute" }} onClick={closeCommentPop}></i>
            {comments.length === 0 ? (
                <>
                    Inga kommentarer än så länge<br></br>

                </>
            ) : (
                <>
                    {comments.map((obj) => (
                        <>
                        
                        <p className="mt-1">{obj.text}</p>
                        {obj.userid == token || user ? <div className="d-flex pb-2">

                        <i className="fa fa-times pt-1" onClick={showDeleteComment} style={{cursor: "pointer"}}>&nbsp;&nbsp;&nbsp;</i>
                        
                        <div  onClick={() => showDeleteComment(obj.id)} style={{cursor: "pointer"}}>Radera</div>
                        <br></br>
                        </div> : <>
                        
                        </>}
                       
                        <i>{obj.name} {obj.date.split("T")[0]} {obj.date.split("T")[1].split(":").splice(0,2).join(":")}</i>
                        <div className="commentLine"></div>
                        </>
                    ))}
                </>
               
            )}



            <textarea className='mt-3 commentText' onChange={updateFormData}></textarea>
            <button className='yesButton mt-3' onClick={postComment}>Lägg till kommentar</button>
        </div>
    </>
}