import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
const redirect = (path) => {
    window.location.href = window.location.href.replace("about", "") + path
}
export default function About() {
    VerifyUser(false);
    renderPageText("about");
    return (
        <>
            <div className="aboutContainer" style={{height: "700px"}}>
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/about.jpg' />
                    </div>
                </div>
                {/* three image rows */}
                <div className="row justify-content-around mt-md-5 mt-0">

                    <div className="col-md-3 col-12 p-0 aboutRow">
                        <div className="row d-flex aboutImageContainer">
                            <img style={{cursor: "pointer"}} src='/images/april/Vår historia_om föreningen.jpg' onClick={() => redirect("history")}/>
                        </div>
                        <div className="row text-center mt-3 mt-lg-0 circleText p-4 pt-0">
                            <h5 className="newTitle title1"></h5>
                            <p className="newText text1"></p>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 p-0 aboutRow">
                        <div className="row d-flex aboutImageContainer">
                            <img style={{cursor: "pointer"}} src='/images/april/Gemensamma utrymmen_om föreningen.jpg' onClick={() => redirect("spaces")}/>
                        </div>
                        <div className="row text-center mt-3 mt-lg-0 circleText p-4 pt-0">
                            <h5 className="newTitle title2"></h5>
                            <p className="newText text2"></p>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 p-0 aboutRow">
                        <div className="row d-flex aboutImageContainer">
                            <img  style={{cursor: "pointer"}} src='/images/april/Anslagstavlan_om föreningen.jpg' onClick={() => redirect("forum")}/>
                        </div>
                        <div className="row text-center mt-3 mt-lg-0 circleText p-4 pt-0">
                            <h5 className="newTitle title3"></h5>
                            <p className="newText text3"></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}