import renderPageText from '../Components/RenderPageTexts';
import VerifyUser from '../Components/VerifyUser';
export default function History() {
    VerifyUser(false);
    renderPageText("history");
    return (
        <>
            <div className="historyContainer">
                {/*      image row */}
                <div className="col-12">
                    <div className="row imageRow">
                        <img src='/images/HR bilder/headers/Historia.jpg' />
                    </div>
                </div>
                {/* history */}
                <div className="row">
                    <div className="col-sm-12 col-md-7 p-5">
                        <h4 className="newTitle title1"></h4>
                        <p className="mt-5 historyParagraph newText text1"></p>
                            <p className="mt-3 historyParagraph newText text2">
                       
                            </p>
                     
                            <p className="mt-3 historyParagraph newText text3">
                           
                            </p>
                            
                            <p className="mt-3 historyParagraph newText text4">
   
                            </p>
                            
                    </div>
                    <div className="col-4 pt-5 mt-5">
                        <div className="historyImgContainer">
                            <img src='/images/april/Vår historia huset ny bild.jpg' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}