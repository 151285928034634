import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import renderPageText from './RenderPageTexts';
import VerifyUser from './VerifyUser';
import APIEnv from "./APIEnv";

export default function NewHeader() {

    const [token, setToken] = useState(false)
    const [user, setUser] = useState([]);
    const [originalText, setOriginalText] = useState([]);
    const [originalTitle, setOriginalTitle] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [showStateLogin, setLogin] = useState(false);
    const [showEdit, setEdit] = useState(false);
    const [showPhoneDD, setDD] = useState(false);
    
    const isSmallScreen = window.innerWidth < 768;

    const foreningOpen = () => {
        const foreningPop = document.getElementsByClassName("foreningPop")[0];
        foreningPop.style.height = "auto";
        foreningPop.style.maxHeight = "600px";
    };
    const foreningClose = () => {
        const foreningPop = document.getElementsByClassName("foreningPop")[0];
        foreningPop.style.maxHeight = "0";
    };
    const foreningToggle = () => {
        setDD(!showPhoneDD)
    }
    const toggleMenu = () => {
        setShowMenu(!showMenu)
    };
    const loginOpen = () => {
        setLogin(true)
    };
    const loginClose = () => {
        setLogin(false)
    };
    const showLogin = () => {
        document.getElementsByClassName("opacity")[0].style.display = "block"
        document.getElementsByClassName("loginWindow")[0].style.display = "block"
    }
    const createAccountOpen = () => {
        document.getElementsByClassName("createAccount")[0].style.opacity = "1";
        document.getElementsByClassName("createAccount")[0].style.pointerEvents = "unset";
    };
    const logOut = () => {
        document.getElementsByClassName("opacity")[0].style.display = "block"
        document.getElementsByClassName("logOutWindow")[0].style.display = "block"
    };
    const originalTextTitle = () => {
        const textElements = document.getElementsByClassName("newText");
        const titleElements = document.getElementsByClassName("newTitle");
        let i = 0;
        while (true) {
            if (!titleElements[i] && !textElements[i]) {
                break;
            }
            if (textElements[i]) {
                if (!originalText.find(obj => obj.id == textElements[i].id)?.text) {
                    window.location.reload();
                } else {
                    textElements[i].innerHTML = originalText.find(obj => obj.id == textElements[i].id)?.text;
                }
            }
            if (titleElements[i]) {
                if (!originalTitle.find(obj => obj.id == titleElements[i].id)?.title) {
                    window.location.reload();
                } else {
                    titleElements[i].innerHTML = originalTitle.find(obj => obj.id == titleElements[i].id)?.title;
                }
            }
            i++;
        };
    };
    const dbChangeContent = async (e) => {
        e.preventDefault();
        const newTitles = document.getElementsByClassName("newTitle");
        const newTexts = document.getElementsByClassName("newText");
        let newContentArray = [];
        let i = 0;
        while (true) {
            if (!newTitles[i] && !newTexts[i]) {
                break;
            }
            let contentTitleString = `
            <title>${newTitles[i]?.innerHTML}</title>
            <text>${newTexts[i]?.innerHTML}</text>`

            newContentArray.push({
                page: document.location.href,
                content: contentTitleString,
                section: i,
                id: newTexts[i] ? newTexts[i].id : newTitles[i].id
            });

            i++;
        };

        try {
            const res = await axios.post(`${APIEnv.apiUrl}/api/pagecontent/change`, {
                token: token,
                PageContent: JSON.stringify(newContentArray),
            })

        } catch {
            console.log("Failed to update page content.")
        }
        setEdit(false)
    };

    useEffect(() => {
        if(showPhoneDD){
            foreningOpen()
        } else {
            foreningClose();
        }
    },[showPhoneDD])
    useEffect(() => {
        const fetchUsers = async (token) => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${token}`);
                setUser(res.data);
            } catch (e) {
                console.log(e)
            }
        };
     

        VerifyUser(false).then((res) => {
            setToken(res.token)
            return res.token
        }).then((res) => {
            if(res){
                fetchUsers(res)
            }
        })
    }, [token]);
    useEffect(() => {
        const titleElement = document.getElementsByClassName("newTitle");
        const textElement = document.getElementsByClassName("newText");
        const editMenu = document.getElementsByClassName("editControlPanel")[0]
        let i = 0;
        while (true) {
            if (!titleElement[i] && !textElement[i]) {
                break;
            }
            titleElement[i]?.setAttribute("contentEditable", showEdit ? "true" : "false");
            textElement[i]?.setAttribute("contentEditable", showEdit ? "true" : "false");
            i++
        }

        if (showEdit) {
            editMenu.style.pointerEvents = "unset";
            editMenu.style.opacity = "1";
        } else {
            editMenu.style.opacity = "0";
            editMenu.style.pointerEvents = "none";
        }

    }, [showEdit]);
    useEffect(() => {
        const foreningPop = document.getElementsByClassName("foreningPop")[0]

        if(token && !isSmallScreen){
            foreningPop.style.right = "300px"
        } else if(!isSmallScreen){
            foreningPop.style.right = "180px"
        }
    },[token])
    useEffect(() => {
        const loginPop = document.getElementsByClassName("loginPop")[0]
        if (showStateLogin) {
            loginPop.style.height = "auto";
            loginPop.style.maxHeight = "200px";
        } else if (loginPop) {
            loginPop.style.maxHeight = "0"
        }
    }, [showStateLogin])
    useEffect(() => {
        const dropDown = document.getElementsByClassName("newMenu")[0]
        const burgerContainer = document.querySelector('.burgerContainer');
        if (showMenu) {
            dropDown.style.maxHeight = "700px";
            dropDown.style.height = "auto";
            burgerContainer.classList.toggle('active');
        } else {
            
            dropDown.style.maxHeight = "0";
            burgerContainer.classList.remove('active');
            setDD(false);
        }
    }, [showMenu])
    useEffect(() => {
        if(isSmallScreen){
        
            document.addEventListener('click', function(event) {
                const element = document.getElementById('phoneDropDownMenu');
                const burger = document.getElementById("burgerContainer");

                if (element && !element.contains(event.target) && burger && !burger.contains(event.target)) {
                // Call the function
                setShowMenu(false)
                } 
            });
        }
        setTimeout(() => {
            const textElements = document.getElementsByClassName("newText");
            const titleElements = document.getElementsByClassName("newTitle");
            let textArray = [];
            let titleArray = [];
            for (const text of textElements) {
                textArray.push({
                    text: text.innerHTML,
                    id: text.id
                })
            }
            for (const title of titleElements) {
                titleArray.push({
                    title: title.innerHTML,
                    id: title.id
                })
            }
            setOriginalText(textArray);
            setOriginalTitle(titleArray);
        }, 1000)
    }, []);

    return (
        <>
        
            <div className="menuContainer">
                <div className='row'>
                    {/*  logo */}
                    <div className="col-md-5 col-12 logoContainer" >
                        <Link to="/"><img src='/images/ikoner/brf _strindbergshuset.png' /></Link>
                    </div>

                    <div className='col-xxl-7 col-md-7 newMenu' >

                   


                            {!isSmallScreen ? 
                                 <div className="newChoice Link p-4 pt-0 pb-0 foreningIpad"  onMouseEnter={foreningOpen} onMouseLeave={foreningClose} >
                                 Om föreningen
                                 <div className='foreningPop p-0 m-0 pt-3' >
                                {/*  <Link style={{ textDecoration: "none" }} to="/about" className='Link' onClick={toggleMenu}><div className="col-12 p-1"> Om föreningen </div></Link> */}
                                 <Link style={{ textDecoration: "none" }} to="/history" className='Link' onClick={toggleMenu}><div className="col-12 p-1">Vår historia</div></Link>
                                 <Link style={{ textDecoration: "none" }} to="/rules" className='Link' onClick={toggleMenu}> <div className="col-12 p-1">Regler & Stadgar</div></Link>
                                 <Link style={{ textDecoration: "none" }} to="/spaces" className='Link' onClick={toggleMenu}> <div className="col-12 p-1">Gemensamma utrymmen</div></Link>
                                 <Link style={{ textDecoration: "none" }} to="/renoveringar" className='Link' onClick={toggleMenu}> <div className="col-12 p-1">Renoveringar</div></Link>
                                 <Link style={{ textDecoration: "none" }} to="/maklare" className='Link' onClick={toggleMenu}> <div className="col-12 p-1">Mäklarinformation</div></Link>
                                 <Link style={{ textDecoration: "none" }} to="/redovisningar" className='Link' onClick={toggleMenu}> <div className="col-12 p-1">Årsredovisningar</div></Link>
                                 <Link style={{ textDecoration: "none" }} to="/meetings" className='Link' onClick={toggleMenu}> <div className="col-12 p-1">Årsstämma</div></Link>
                                 </div>
                             </div> : <></>}





                        {isSmallScreen ? 
                        <div id="phoneDropDownMenu">
                        <div onClick={foreningToggle} className='newChoice Link'>Om föreningen</div>
                         <div className='foreningPop mt-3' onClick={foreningOpen} onMouseEnter={null}> 
                         <Link style={{ textDecoration: "none" }} to="/history" className='Link' onClick={toggleMenu}><div className="col-12 mt-1 mb-1">Vår historia</div></Link>
                         <Link style={{ textDecoration: "none" }} to="/rules" className='Link' onClick={toggleMenu}> <div className="col-12 mt-1 mb-1">Regler & Stadgar</div></Link>
                            <Link style={{ textDecoration: "none" }} to="/spaces" className='Link' onClick={toggleMenu}> <div className="col-12  mt-2 mb-1">Gemensamma utrymmen</div></Link>
                            <Link style={{ textDecoration: "none" }} to="/renoveringar" className='Link' onClick={toggleMenu}> <div className="col-12 mt-1 mb-1">Renoveringar</div></Link>  
                            <Link style={{ textDecoration: "none" }} to="/maklare" className='Link' onClick={toggleMenu}> <div className="col-12 mt-1 mb-1">Mäklarinformation</div></Link>
                            <Link style={{ textDecoration: "none" }} to="/redovisningar" className='Link' onClick={toggleMenu}> <div className="col-12 mt-1 mb-1">Årsredovisningar</div></Link>
                            <Link style={{ textDecoration: "none" }} to="/meetings" className='Link' onClick={toggleMenu}> <div className="col-12 mt-1 mb-1">Årsstämma</div></Link>
                            
                            
                        </div>
                        </div>
                        : <></>}


                           
                        <Link style={{ textDecoration: "none" }} to="/contact" className='Link' onClick={toggleMenu}><div className='Link newChoice p-4 pb-0 pt-0'>Kontakt</div></Link>
                        {token ? <Link style={{ textDecoration: "none" }} to="/forum" className='Link' onClick={toggleMenu}><div className='Link newChoice p-4 pt-lg-0 pt-md-0 pt-3  pb-lg-0 pb-md-0 pb-4'>Anslagstavlan</div></Link> : <></>}
                        {token ?
                            isSmallScreen ? <>
                                <Link style={{ textDecoration: "none", color: "white" }} to="/profile" onClick={toggleMenu}><div className='col-12 p-3 Link newChoice'>Profil</div></Link>
                              
                      
                               {user.isAdmin ? <>
                                    <div className='col-12 Link newChoice p-3' onClick={() => { setEdit(true); toggleMenu() }}>Redigera sida</div>
                                    <div className='col-12 p-0 Link newChoice' onClick={() => window.location.href = `https://pbforvaltning.realportal.nu`}>Bo-portalen</div>
                                </> : <>
                                <div className='col-12 p-3 Link newChoice' onClick={() => window.location.href = `https://pbforvaltning.realportal.nu`}>Bo-portalen</div>
                                </>}
                        

                                
                                <div className='col-12 pb-3 Link newChoice' onClick={() => { toggleMenu(); logOut(); }}>Logga ut</div>
                            </> : <>
                                <div className='Link newChoice' onMouseEnter={loginOpen} onMouseLeave={loginClose} style={{borderRight: 0}} >
                                    <i className='fa fa-user'> </i>
                                    <div className='loginPop' >
                                        <Link style={{ textDecoration: "none", color: "black" }} to="/profile"><div className='col-12 pt-1 mt-3'>Profil</div></Link>
                                        
                                     
                                        {user.isAdmin ? <>
                                            <div className='col-12 pt-1 mt-3' onClick={() => { setEdit(true); toggleMenu() }}>Redigera sida</div>
                                        </> : <></>}
                                     
                                        

                                        <div className='col-12 pt-1 mt-3' onClick={() => window.location.href = `https://pbforvaltning.realportal.nu`}>Bo-portalen</div>
                                        <div className='col-12 pt-1 mt-3 pb-2' onClick={() => { toggleMenu(); logOut(); }}>Logga ut</div>
                                    </div>
                                </div>

                            </> : <>
                            {isSmallScreen ? <>
                                <div className='col-12 mt-md-4 mt-3 pt-1 pb-1 newChoice Link' onClick={() => { toggleMenu(); showLogin() }}> Logga in</div>
                                            <div className='col-12 pt-1 pb-1 newChoice Link' onClick={() => { toggleMenu(); createAccountOpen() }} >Skapa konto</div>
                            </> : <>
                            <div className="newChoice" onMouseEnter={loginOpen} onMouseLeave={loginClose}>
                                    <div className="container Link p-4 pt-0 pb-0" >
                                        Logga in
                                        <div className='loginPop p-0 m-0'>
                                            <div className='col-12 mt-md-4 mt-3 pt-1 pb-1' onClick={() => { toggleMenu(); showLogin() }}> Logga in</div>
                                            <div className='col-12 mt-1 pt-1 pb-1' onClick={() => { toggleMenu(); createAccountOpen() }} >Skapa konto</div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                            </>}
                          
                    </div>
                </div>
            </div>

            {/*  burger */}
            <div className='burgerContainer' id="burgerContainer" onClick={toggleMenu}>
                <div className='patty' id="patty1"></div>
                <div className='patty' id="patty2"></div>
                <div className='patty' id="patty3"></div>
                <p className='mt-2 text-center'></p>
            </div>

            {/* editText */}

            <div className='row justify-content-center'>
                <div className='editControlPanel col-lg-3 col-md-4 col-sm-4 col-10 pt-2 text-center'>
                    Ändra innehåll
                    <form onSubmit={dbChangeContent} className='mt-2'>
                        <div className='d-flex justify-content-around'>
                        <button type="submit">Spara ändringar</button>
                            <div className='cancelButton p-1' onClick={() => {
                                setEdit(false);
                                originalTextTitle();
                            }}>Avbryt</div>
                            
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}