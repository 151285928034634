import { useState, useEffect, useRef } from "react";
import axios from "axios";
import DeleteUser from "../Components/DeleteUser";
import VerifyUser from '../Components/VerifyUser';
import APIEnv from "../Components/APIEnv";
export default function Profile() {
  
    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        VerifyUser(true).then((res) => {
            setToken(res.token)
            setLoading(false)
        })
    },[token])
    
    const [user, setUser] = useState([]);
    useEffect(() => {
        if(!loading){
            const fetchUsers = async () => {
                try {
                    const res = await axios.get(`${APIEnv.apiUrl}/user/getuser?salt=${token}`);
                    setUser(res.data);
                } catch (e) {
                    console.log(e)
                }
            };
            fetchUsers();
        }
    }, [loading]);

    const [addUserShow, setUserShow] = useState(false);
    useEffect(() => {
        
        const popUp = document.getElementsByClassName("popUpContainer")[0];

        if (addUserShow && !loading) {
            document.getElementsByClassName("profileOptions")[0].style.pointerEvents = "none"
            popUp.innerHTML = "Lägg till användare";
            popUp.className = popUp.className + " p-4"
            const newInput = document.createElement("input");
            newInput.setAttribute("placeholder", "Användarens Email");
            newInput.className = "mt-3 col-xxl-6 col-xl-10 col-md-6 col-sm-11 userNameInput form-control";

            const newButton = document.createElement("button");
            newButton.innerHTML = "Lägg till användare";
            newButton.className = "newUserButton mt-3";
            newButton.addEventListener("click", async () => {
                const userNameInput = document.getElementsByClassName("userNameInput")[0];
                const adminInput = document.getElementsByClassName("shouldBeAdmin")[0];

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (userNameInput.value == "") {
                    alert("Fyll i email-adressen.");
                } else if (!emailRegex.test(userNameInput.value)) {
                    alert("Vänligen använd en email-adress.")
                } else {
                    let body = {
                        username: userNameInput.value,
                        isAdmin: adminInput.checked,
                        salt: token
                    }
                    try {
                        const res = await axios.post(`${APIEnv.apiUrl}/api/user/newemptyuser`, body);
                        alert("Användaren skapad!")
                        setUserShow(false)
                    } catch (e) {
                        console.log(e);
                    }
                }
            });

            const cancelButton = document.createElement("button");
            cancelButton.innerHTML = "Avbryt";
            cancelButton.className = "newUserButton mt-3";
            cancelButton.addEventListener("click", () => {
                setUserShow(false);
            });

            const dflexContainer = document.createElement("div");
            dflexContainer.className = "col-12 mt-3 d-flex justify-content-between"
            dflexContainer.append(newButton);
            dflexContainer.append(cancelButton);

            const adminCheckBox = document.createElement("input");
            adminCheckBox.type = "checkbox";
            adminCheckBox.className = "m-4 mb-0 shouldBeAdmin";

            const dflexContainer2 = document.createElement("div");
            dflexContainer2.className = "col-xxl-8 col-xl-11";
            dflexContainer2.append("Administratör");
            dflexContainer2.append(adminCheckBox);

            popUp.append(newInput);
            popUp.append(dflexContainer2)
            popUp.append(dflexContainer)

            popUp.style.opacity = "1";
            popUp.style.pointerEvents = "unset";

        } else if(!loading){
            document.getElementsByClassName("profileOptions")[0].style.pointerEvents = "unset"
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
        }
    }, [addUserShow, loading]);


    const showDelete = () => {
        const popUp = document.getElementsByClassName("deleteUserContainer")[0];
        popUp.style.opacity = "1";
        popUp.style.pointerEvents = "unset";
    };

    const isSmallScreen = window.innerWidth < 768;
    const [changeShow, setChange] = useState(false);
    useEffect(() => {
        const popUp = document.getElementsByClassName("popUpContainer")[0];
        if(changeShow && !loading){
            document.getElementsByClassName("profileOptions")[0].style.pointerEvents = "none"
            popUp.innerHTML = ""
            popUp.append("Ändra detaljer")
            const rowcon = document.createElement("div");


            let colcon1 = document.createElement("div");
            let colcon2 = document.createElement("div");
            let colcon3 = document.createElement("div");
            if(isSmallScreen){
                colcon1.className = "col-10 text-start p-0"
                colcon2.className = "col-10 text-start p-0"
                colcon3.className = "col-10 text-start p-0"
                rowcon.className = "row pb-4 d-flex justify-content-center";
            } else {
                colcon1.className = "col-12 text-start"
                colcon2.className = "col-12 text-start mt-3"
                colcon3.className = "col-12 text-start mt-2"
                rowcon.className = "row m-5 mt-0";
            }

            colcon1.append("Email")
            colcon2.append("Telefon")
            colcon3.append("Profilbild")

            const emailInput = document.createElement("input");
            emailInput.className = "emailInput form-control col-12";
            emailInput.setAttribute("placeholder", "Email");


            const phoneInput = document.createElement("input");
            phoneInput.className = "phoneInput form-control mb-3 col-12"
            phoneInput.setAttribute("placeholder", "Telefon");

            const imageInput = document.createElement("input");
            imageInput.className = "imageInput col-12 form-control"
            imageInput.id = "profilePicInput"
            imageInput.setAttribute("type", "file")
            imageInput.setAttribute("accept", "image/*")

            const buttonRow = document.createElement("div");
            buttonRow.className = " col-md-9 col-10 d-flex justify-content-between";
            
            const cancelButton = document.createElement("button");
            cancelButton.innerHTML = "Avbryt"
            cancelButton.className = "newUserButton mt-3";
            cancelButton.addEventListener("click", () => {
                setChange(false);
            });

            const subMitButton = document.createElement("button");
            subMitButton.innerHTML = "Spara detaljer"
            subMitButton.className = "newUserButton mt-3";
            subMitButton.addEventListener("click", async () => {
                const userNameInput = document.getElementsByClassName("emailInput")[0];
                const phoneInput = document.getElementsByClassName("phoneInput")[0];

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                const phoneRegex = /[a-zA-Z]/

                if (!emailRegex.test(userNameInput.value) && userNameInput.value && userNameInput.value.trim() !== "") {
                    alert("Ogiltig email-adress.")
                } else if(!phoneRegex.test(phoneInput) && phoneInput && phoneInput.trim() !== ""){
                    alert("Ogiltigt telefonnummer.")
                } else {
                    let body = {
                        username: userNameInput.value.trim() == "" ? user.email : userNameInput.value,
                        phone: phoneInput.value,
                        salt: token
                    }
                    try {
                        const res = await axios.post(`${APIEnv.apiUrl}/api/user/changeuser`, body);
                        const fileInput = document.getElementById("profilePicInput")
                        const imageFile = fileInput.files[0]
                        if(imageFile){
                            const formData = new FormData();
                            formData.append("file", imageFile);
                            formData.append("email", user.email);
                            const imageRes = await axios.post(`${APIEnv.apiUrl}/api/user/uploadimg`, formData);
                        }
                        alert("Deljerna ändrades!")
                        setChange(false)
                        window.location.reload()
                    } catch (e) {
                        console.log(e);
                    }
                }
            });

            if(isSmallScreen){
                colcon2.style.marginTop = "20px"
            }
            colcon3.append(imageInput)
            colcon2.append(phoneInput)
            colcon1.append(emailInput)

            rowcon.append(colcon1);
            rowcon.append(colcon2);
            rowcon.append(colcon3)

            buttonRow.append(subMitButton)
            buttonRow.append(cancelButton)
            popUp.append(rowcon)
            popUp.append(buttonRow)
            

            popUp.style.opacity = "1";
            popUp.style.pointerEvents = "unset";

        } else if(!loading) {
            document.getElementsByClassName("profileOptions")[0].style.pointerEvents = "unset"
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
        }
    },[changeShow, loading])

    const changepw = () => {
        const popUp = document.getElementsByClassName("popUpContainer")[0]
        popUp.innerHTML = ""
        popUp.style.opacity = "1";
        popUp.style.pointerEvents = "unset";
        popUp.className = popUp.className + " p-4"
        
        const newpw1 = document.createElement("input")
        newpw1.type = "password"
        newpw1.placeholder = "Nytt lösenord"
        newpw1.className = "mt-3 form-control"

        const newpw2 = document.createElement("input")
        newpw2.type = "password"
        newpw2.placeholder = "Repetera lösenord"
        newpw2.className = "mt-3 form-control"

        popUp.append("Ändra lösenord")
        popUp.append(newpw1)
        popUp.append(newpw2)

        const buttonDiv = document.createElement("div")
        buttonDiv.className = "d-flex justify-content-between col-12 mt-3"

        const yesButton = document.createElement("button")
        yesButton.innerHTML = "Byt lösenord"
        yesButton.className = "yesButton m-2"

        const noButton = document.createElement("button")
        noButton.innerHTML = "Avbryt"
        noButton.className = "noButton m-2"

        noButton.addEventListener("click", () => {
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
        })
        yesButton.addEventListener("click", async () => {
            if(newpw1.value != newpw2.value){
                alert("Lösenorden matchar inte.")
            } else if(newpw1.value == "") { 
                alert("Lösenordet kan inte vara tomt.")
            } else  if(newpw1.value.length < 7){
                alert("Lösenordet måste vara minst 7 tecken långt.")
            } else {
                try{
                    const res = await axios.post(`${APIEnv.apiUrl}/user/changepw`, {
                       password: newpw1.value,
                       token: token
                    })
                    alert("Lösenordet ändrat!")
                    window.location.reload()
                } catch(err){
                    console.log(err)
                }
            }
        })
        buttonDiv.append(yesButton)
        buttonDiv.append(noButton)
        popUp.append(buttonDiv)
    }

    if(!token && !loading){
        window.location.href = "/"
    }

    return (
        <>
    {token ? (
    <>
    {loading ? <></> : <>
    <DeleteUser token={token}/>
    </>}
        <div className="profileContainer p-5">
            <div className="col-lg-8">
                <h1>Profil</h1>
            </div>
            <div className="row mt-4">
                <div className="col-md-4 col-sm-12">
                    <div className="profilePicContainer">
                        {user.imagePath == "" ?
                        <img src="/images/ikoner/20171206_01.jpg" /> :
                        <img src={`${APIEnv.apiUrl}/api/blogs/getimage?imagePath=${encodeURIComponent(user.imagePath)}`} />
                        }
                        
                    </div>
                </div>
                <div className="col-md-8 col-sm-12 profileOptions">
                    <div className="row m-md-5 m-2 mt-4">
                        <b className="p-0">{user.name}</b>
                    </div>
                    <div className="row m-md-5 m-2 mt-4">
                        <b className="p-0">Medlem sedan: </b>{user.regDate?.split("T")[0]}
                    </div>
                    <div className="row m-md-5 m-2 mt-4">
                        <b className="p-0">Email / Användarnamn:</b>{user.email}
                    </div>
                    <div className="row m-md-5 m-2 mt-4">
                        <b className="p-0">Telefon:</b>{user.phone}
                    </div>
               
                        <div className="row mt-md-0 mt-4">
                            <div className="col-md-6 col-12">
                            <button className="changePassword p-2" onClick={changepw}>Ändra lösenord</button>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-3">
                            <button className="changePassword p-2" onClick={() => setChange(true)}>Ändra detaljer&nbsp;</button>
                            </div>
                        </div>
                    {user.isAdmin ? <>
                        <div className="row mt-md-5 mt-3">
                            <div className="col-md-6 col-12">
                            <button className="changePassword p-2" onClick={() => setUserShow(true)}>Lägg till användare</button>
                            </div>
                            <div className="col-md-6 col-12 mt-md-0 mt-3">
                            <button className="changePassword p-2" onClick={showDelete}>Ta bort användare</button>
                            </div>
                        </div>
                    </> : <></>}
                </div>
            </div>
        </div>
        </>)
     : <>
     {!loading && !token ? <div onLoad={() => window.location.href = "/"}>Loading...</div> : <></>}
     </>
     
     }
     </>
    )
}